import AlertBox from "@/components/AlertBox";
import DialogBox from "@/components/DialogBox";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { DialogClose } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BiBookAdd } from "react-icons/bi";
import { FiFilter, FiPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { IoMdCloudDownload } from "react-icons/io";
import Loading from "@/components/Loading";
import { CiEdit } from "react-icons/ci";
import { useSelector } from "react-redux";

function Inventory() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [recordData, setRecordData] = useState([]);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [rate, setRate] = useState(0);
  const [description, setDescription] = useState("");
  const [hsnNo, setHsnNo] = useState("");
  const [gstRate, setGstRate] = useState(0);
  const [name, setName] = useState("");
  const navigate = useNavigate()
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated)

  if(!isAuthenticated) {
    return navigate("/")
  }

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/inventory/get?search=${search}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setRecordData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  async function deleteRecord(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/inventory/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setReload((prev) => !prev);
    }
  }

  async function addRecord(id) {
    let data = {
      name: name,
      hsnNo: hsnNo,
      rate: rate || 0, // Default to 0 if rate is undefined
      gstRate: gstRate || 0, // Default to 0 if gstRate is undefined
      description: description,
    };

    if (id !== undefined && id.length > 0) {
      data = { ...data, id: id };
    }

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/inventory/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Record added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Record add failed");
      console.log(error);
    } finally {
      setLoading(false);
      setName("");
      setHsnNo(""); // Reset hsnNo properly
      setRate(0); // Reset rate to 0
      setGstRate(0); // Reset gstRate to 0
      setDescription(""); // Reset description
    }
  }

  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-center items-center py-5 px-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <h1 className="text-[40px] font-semibold">Inventory</h1>
      <div className="w-full mt-5">
        <div className="w-full flex justify-end items-center gap-3">
          <div className="flex items-center gap-2">
            <DialogBox
              title={
                <p className="flex items-center gap-1 font-medium max-w-full">
                  <BiBookAdd size={"18px"} /> Add New Item
                </p>
              }
            >
              <div className="w-full grid grid-cols-2 gap-3">
                <InputField
                  label="Name"
                  onChange={(e) => setName(e?.target?.value)}
                />
                <InputField
                  label="HSN/SAC No"
                  onChange={(e) => setHsnNo(e?.target?.value)}
                />
                <InputField
                  label="Rate"
                  onChange={(e) => setRate(e?.target?.value)}
                />
                <InputField
                  label="GST Rate"
                  onChange={(e) => setGstRate(e?.target?.value)}
                />
              </div>
              <div className="w-full space-y-2">
                <Label>Description</Label>
                <textarea
                  className="w-full focus:outline-none focus:border-slate-500 border p-3 rounded-[5px] h-[7rem]"
                  onChange={(e) => setDescription(e?.target?.value)}
                ></textarea>
              </div>
              <DialogClose>
                <Button onClick={() => addRecord()}>Save</Button>
              </DialogClose>
            </DialogBox>
            <section className="flex gap-2">
              <InputField
                placeholder="Search"
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <Button onClick={() => setReload((prev) => !prev)}>Search</Button>
            </section>
          </div>
        </div>
        <div className="border mt-5 px-5 rounded-[8px]">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-center">Name</TableHead>
                <TableHead className="text-center">HSN/SAC No</TableHead>
                <TableHead className="text-center">GST Rate</TableHead>
                <TableHead className="text-center">Rate</TableHead>
                <TableHead className="text-center">Edit</TableHead>
                <TableHead className="text-center">Delete</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {recordData &&
                recordData?.map((e, i) => (
                  <TableRow key={i}>
                    <TableCell className="text-center">{e?.name}</TableCell>
                    <TableCell className="text-center">{e?.hsnNo}</TableCell>
                    <TableCell className="text-center">{e?.gstRate}%</TableCell>
                    <TableCell className="text-center">{e?.rate}</TableCell>
                    <TableCell className="text-center">
                      <DialogBox
                        title={<CiEdit size={"20px"} />}
                        contentTitle={"Add A Remark"}
                      >
                        <div className="w-full grid grid-cols-2 gap-3">
                          <InputField
                            label="Name"
                            onChange={(e) => setName(e?.target?.value)}
                            defaultValue={e?.name}
                          />
                          <InputField
                            label="HSN/SAC No"
                            onChange={(e) => setHsnNo(e?.target?.value)}
                            defaultValue={e?.hsnNo}
                          />
                          <InputField
                            label="Rate"
                            onChange={(e) => setRate(e?.target?.value)}
                            defaultValue={e?.rate}
                          />
                          <InputField
                            label="GST Rate"
                            onChange={(e) => setGstRate(e?.target?.value)}
                            defaultValue={e?.gstRate}
                          />
                        </div>
                        <div className="w-full space-y-2">
                          <Label>Description</Label>
                          <textarea
                            className="w-full focus:outline-none focus:border-slate-500 border p-3 rounded-[5px] h-[7rem]"
                            onChange={(e) => setDescription(e?.target?.value)}
                          ></textarea>
                        </div>
                        <DialogClose>
                          <Button onClick={() => addRecord(e?._id)}>
                            Save
                          </Button>
                        </DialogClose>
                      </DialogBox>
                    </TableCell>
                    <TableCell className="text-center">
                      <Button
                        variant="destructive"
                        onClick={() => deleteRecord(e?._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
