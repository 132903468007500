import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './pages/Layout'
import Signin from './pages/Signin'
import { useDispatch, useSelector } from 'react-redux'
import Dashboard from './pages/Dashboard'
import Profile from './pages/Profile'
import axios from 'axios'
import { login, logout } from './store/auth.slice'
import { SERVER_URL } from './constant'
import Crm from './pages/Crm'
import AddClient from './pages/AddClient'
import EditClient from './pages/EditClient'
import Invoice from './pages/Invoice'
import Quotation from './pages/Quotation'
import AccountBook from './pages/AccountBook'
import InvoiceReport from './pages/InvoiceReport'
import Inventory from './pages/Inventory'
import Service from './pages/Service'
import ServiceReport from './pages/ServiceReport'
import QuotationReport from './pages/QuotationReport'
import SignUp from './pages/SignUp'

function App() {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function getUser() {
    try {
      setLoading(true);
      const res = await axios.get(`${SERVER_URL}/api/v1/user/get-user`, {
        withCredentials: true,
      });
      console.log(res.data);
      dispatch(login({ userData: res.data?.data }));
    } catch (error) {
      dispatch(logout());
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUser();
  }, [isAuthenticated]);

  //console.log(userData);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route path='' element={isAuthenticated === true ? <Dashboard /> : <Signin />} />
          <Route path='my-profile' element={<Profile/>} />
          <Route path='crm' element={<Crm/>} />
          <Route path='add-client' element={<AddClient/>} />
          <Route path='edit-client/:id' element={<EditClient/>} />
          <Route path='invoice/generate' element={<Invoice/>} />
          <Route path='quotation/generate' element={<Quotation/>} />
          <Route path='account-book' element={<AccountBook/>} />
          <Route path='report/invoice' element={<InvoiceReport/>} />
          <Route path='report/service' element={<ServiceReport/>} />
          <Route path='report/quotation' element={<QuotationReport/>} />
          <Route path='inventory' element={<Inventory/>} />
          <Route path='services' element={<Service/>} />
        </Route>
        <Route path='/1ebd84ff-122b-6a3f-89ab-7e21c3848b29' element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
