import React, { useEffect, useState } from "react";
import DialogBox from "./DialogBox";
import InputField from "./InputField";
import { Button } from "./ui/button";
import { useForm } from "react-hook-form";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import AlertBox from "./AlertBox";
import { useNavigate } from "react-router-dom";
import { FiFilePlus } from "react-icons/fi";
import { Label } from "./ui/label";
import { DialogClose } from "./ui/dialog";
import { LuPencilLine } from "react-icons/lu";

function UpdateItem({ setReload, gstType, invoiceId, data }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [itemName, setItemName] = useState("");
  const [hsnNo, setHsnNo] = useState("");
  const [quantity, setQuantity] = useState(Number);
  const [rate, setRate] = useState(Number);
  const [amount, setAmount] = useState(Number);
  const [gstRate, setGstRate] = useState(Number);
  const [cgst, setCgst] = useState(Number);
  const [sgst, setSgst] = useState(Number);
  const [igst, setIgst] = useState(Number);
  const [total, setTotal] = useState(Number);
  const [unit, setUnit] = useState("pcs");
  const [message, setMessage] = useState("");

  async function addItem() {
    if (invoiceId?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please click on save and continue first");
      return null;
    }

    let updateData = {};

    if (gstType === "IGST") {
      updateData = {
        itemName,
        hsnNo,
        quantity,
        unit,
        rate,
        amount,
        gstRate,
        igst,
        total,
      };
    } else {
      updateData = {
        itemName,
        hsnNo,
        quantity,
        unit,
        rate,
        amount,
        gstRate,
        cgst,
        sgst,
        total,
      };
    }

    setReload((prev) => !prev);

    console.log(data);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/item/update`,
        method: "put",
        data: {
          id: invoiceId,
          item: updateData,
          itemName: data?.itemName,
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Add failed");
      console.log(error);
    } finally {
      setLoading(false);
      setDialogOpen(false);
    }
  }

  console.log(data);

  useEffect(() => {
    setAmount(Math.ceil(Number(rate * quantity)));
  }, [rate, quantity]);

  useEffect(() => {
    if (gstType === "IGST") {
      setIgst(Math.ceil(Number(amount * (gstRate / 100))));
    } else {
      setCgst(Math.ceil(Number((amount * (gstRate / 100)) / 2)));
      setSgst(Math.ceil(Number((amount * (gstRate / 100)) / 2)));
    }
  }, [gstRate, rate]);

  useEffect(() => {
    setTotal(Math.ceil(Number(amount + amount * (gstRate / 100))));
  }, [gstRate, amount, rate]);

  useEffect(() => {
    if (data != undefined) {
      setRate(data?.rate);
      setGstRate(data?.gstRate);
      setQuantity(data?.quantity);
    }
  }, []);

  if (gstType === "IGST") {
    return (
      <>
        {success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        {!success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <DialogBox
          title={<LuPencilLine size={"23px"} className="text-sky-600" />}
          buttonClass={
            "p-0 h-auto bg-transparent text-black border-transparent hover:bg-slate-100"
          }
          desc={"Add A New Item In The Invoice"}
          width="70vw"
        >
          <div className="w-full grid grid-cols-2 gap-3">
            <InputField
              label="Item Name"
              onChange={(e) => setItemName(e?.target?.value)}
              defaultValue={data?.itemName}
            />
            <InputField
              label="HSN/SAC"
              onChange={(e) => setHsnNo(e?.target?.value)}
              defaultValue={data?.hsnNo}
            />
            <InputField
              label="Quantity"
              type="number"
              defaultValue={data?.quantity}
              onChange={(e) =>
                setQuantity(() => {
                  if (e?.target?.value >= 1) {
                    return e?.target?.value;
                  } else {
                    return 0;
                  }
                })
              }
            />
            <InputField
              label="Rate"
              onChange={(e) => setRate(e?.target?.value)}
              defaultValue={data?.rate}
            />
            <InputField label="Amount" readOnly value={amount} />
            <InputField
              label="GST Rate"
              onChange={(e) => setGstRate(Number(e?.target?.value))}
              defaultValue={data?.gstRate}
            />
            <InputField label="IGST" readOnly value={igst} />
            <InputField label="Total" readOnly value={total} />
            <section className="flex flex-col gap-[0.725rem]">
              <Label>
                Select Unit{" "}
                {data?.unit && (
                  <span className="text-slate-500">(Prev: {data?.unit})</span>
                )}
              </Label>
              <select
                className="h-[2.3rem] p-[0.37rem] rounded-md bg-transparent border"
                defaultValue={""}
                onChange={(e) => setUnit(e?.target?.value)}
              >
                <option value={"pcs"}>Pcs</option>
                <option value={"gram"}>Gram</option>
                <option value={"kg"}>KG</option>
                <option value={"litre"}>Litre</option>
                <option value={"box"}>Box</option>
                <option value={"service"}>Service</option>
              </select>
            </section>
          </div>
          <DialogClose>
            <Button onClick={addItem} className="mt-5">
              Add
            </Button>
          </DialogClose>
        </DialogBox>
      </>
    );
  }

  return (
    <>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <DialogBox
        title={<LuPencilLine size={"23px"} className="text-sky-600" />}
        buttonClass={
          "p-0 h-auto bg-transparent text-black border-transparent hover:bg-slate-100"
        }
        desc={"Add A New Item In The Invoice"}
      >
        <div className="w-full grid grid-cols-2 gap-3">
          <InputField
            label="Item Name"
            onChange={(e) => setItemName(e?.target?.value)}
            defaultValue={data?.itemName}
          />
          <InputField
            label="HSN/SAC"
            onChange={(e) => setHsnNo(e?.target?.value)}
            defaultValue={data?.hsnNo}
          />
          <InputField
            label="Quantity"
            type="number"
            onChange={(e) =>
              setQuantity(() => {
                if (e?.target?.value >= 1) {
                  return e?.target?.value;
                } else {
                  return 0;
                }
              })
            }
            defaultValue={data?.quantity}
          />
          <InputField
            label="Rate"
            onChange={(e) => setRate(e?.target?.value)}
            defaultValue={data?.rate}
          />
          <InputField label="Amount" readOnly value={amount || data?.amount} />
          <InputField
            label="GST Rate"
            onChange={(e) => setGstRate(Number(e?.target?.value))}
            defaultValue={data?.gstRate}
          />
          <InputField label="CGST" readOnly value={cgst || data?.cgst} />
          <InputField label="SGST" readOnly value={sgst || data?.sgst} />
          <InputField label="Total" readOnly value={total || data?.total} />
          <div className="flex flex-col gap-[0.725rem] ">
            <Label>
              Select Unit{" "}
              {/* {productData?.consultMode && (
                <span className="text-slate-500">
                  (Prev: {productData?.consultMode})
                </span>
              )} */}
            </Label>
            <select
              className="max-w-[24rem] h-[2.3rem] p-[0.37rem] rounded-md bg-transparent border"
              defaultValue={""}
              onChange={(e) => setUnit(e?.target?.value)}
            >
              <option value={"pcs"}>Pcs</option>
              <option value={"gram"}>Gram</option>
              <option value={"kg"}>KG</option>
              <option value={"litre"}>Litre</option>
              <option value={"box"}>Box</option>
              <option value={"service"}>Service</option>
            </select>
          </div>
        </div>
        <DialogClose asChild>
          <Button onClick={addItem} className="mt-5">
            Add
          </Button>
        </DialogClose>
      </DialogBox>
    </>
  );
}
export default UpdateItem;
