import AlertBox from "@/components/AlertBox";
import DialogBox from "@/components/DialogBox";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BiBookAdd } from "react-icons/bi";
import { FiFilter, FiPlus } from "react-icons/fi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoMdCloudDownload } from "react-icons/io";
import Loading from "@/components/Loading";
import { CiEdit } from "react-icons/ci";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SlCalender } from "react-icons/sl";
import ConfirmBox from "@/components/ConfirmBox";
import { TfiReload } from "react-icons/tfi";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";

function QuotationReport() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [recordData, setRecordData] = useState([]);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [rate, setRate] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [name, setName] = useState("");
  const [selectedClient, setSelectedClient] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [typeOfService, setTypeOfService] = useState("");
  const [timeSpan, setTimeSpan] = useState(1);
  const [clientData, setClientData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownTitle, setDropdownTitle] = useState("Select A Client");
  const [endDate, setEndDate] = useState(undefined);
  const [startSearchDate, setStartSearchDate] = useState(undefined);
  const [enableAddService, setEnableAddService] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);

  if (!isAuthenticated) {
    return navigate("/");
  }

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/get/quotation/report?search=${search}&startDate=${startSearchDate}&endDate=${endDate}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);

      setRecordData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload, startSearchDate, endDate]);

  //console.log(recordData);

  async function deleteRecord(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setReload((prev) => !prev);
    }
  }

  async function changeStatus(id, status) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/change/deal/status/${id}/${status}`,
        method: "put",
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setReload((prev) => !prev);
    }
  }

  async function generateReport() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/generate/quotation/report`,
        method: "post",
        data: {
          startDate: startSearchDate,
          endDate: endDate,
          data: recordData,
          topic: search,
        },
        withCredentials: true,
      });
      console.log(res.data.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Redirecting in some time...");
      window.open(res.data?.data?.link, "_blank");
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setMessage("Report generate failed");
    } finally {
      setLoading(false);
    }
  }

  async function addRemarks(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/add`,
        method: "post",
        data: {
          remark: remarks,
          id: id,
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  //console.log(recordData);

  return (
    <div className="w-full flex flex-col justify-center items-center py-5 px-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <h1 className="text-[40px] font-semibold">Quotation Report</h1>
      <div className="w-full mt-5">
        <div className="w-full flex justify-between items-center gap-3">
          <div className="flex gap-2 items-center">
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {startSearchDate
                    ?.toString()
                    ?.split(" ")
                    ?.slice(1, 4)
                    ?.join(" ") || "Start Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={startSearchDate}
                  onSelect={setStartSearchDate}
                />
              </PopoverContent>
            </Popover>
            <p>To</p>
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {endDate?.toString()?.split(" ")?.slice(1, 4)?.join(" ") ||
                    "End Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={endDate}
                  onSelect={setEndDate}
                />
              </PopoverContent>
            </Popover>
            <Button
              onClick={() => {
                setStartDate(undefined);
                setEndDate(undefined);
              }}
            >
              Clear
            </Button>
          </div>
          <div className="flex items-center gap-2">
            <section className="flex gap-2">
              <InputField
                placeholder="Search"
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <Button onClick={() => setReload((prev) => !prev)}>Search</Button>
            </section>
          </div>
        </div>
        <div className="flex justify-end items-center w-full mt-5 gap-3">
          <NavLink
            to={"/quotation/generate"}
            className="font-semibold text-blue-500 hover:underline"
          >
            New Quotation
          </NavLink>
          <Button
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => {
              setReload((prev) => !prev);
              setSearch("");
            }}
          >
            <TfiReload size={"20px"} /> Refresh
          </Button>
          <Button className="flex items-center gap-2" onClick={generateReport}>
            <IoMdCloudDownload size={"20px"} /> Download
          </Button>
        </div>
        <div className="border mt-5 px-5 rounded-[8px]">
          <Table>
            <TableHeader>
              <TableRow className="text-[13px]">
                <TableHead className="text-center">Quotation Date</TableHead>
                <TableHead className="text-center">
                  Quotation Due Date
                </TableHead>
                <TableHead className="text-center">Client Name</TableHead>
                <TableHead className="text-center">Invoice Number</TableHead>
                <TableHead className="text-center">GST Type</TableHead>
                <TableHead className="text-center">GST Amount</TableHead>
                <TableHead className="text-center">Discount</TableHead>
                <TableHead className="text-center">
                  Total Bill(include GST)
                </TableHead>
                <TableHead className="text-center">
                  Total Amount(exclude GST)
                </TableHead>
                <TableHead className="text-center">Deal Status</TableHead>
                <TableHead className="text-center">Remarks</TableHead>
                <TableHead className="text-center">
                  Download Quotation
                </TableHead>
                <TableHead className="text-center">Delete Invoice</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {recordData &&
                recordData?.map((e, i) => (
                  <TableRow
                    className={`text-[13px] ${
                      e?.dealStatus === "Waiting"
                        ? "bg-yellow-50"
                        : e?.dealStatus === "Success"
                        ? "bg-green-50"
                        : "bg-red-50"
                    }`}
                  >
                    <TableCell className="font-medium text-center">
                      {
                        new Date(e?.dateStamp)
                          ?.toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                          })
                          ?.toString()
                          ?.split(",")[0]
                      }
                    </TableCell>
                    <TableCell className="font-medium text-center">
                      {e?.dueDate?.toString()?.split("T")[0]}
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.client?.name}
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.invoiceNumber}
                    </TableCell>
                    <TableCell className="text-center">{e?.gstType}</TableCell>
                    <TableCell className="text-center">
                      {e?.gstType === "none"
                        ? "N/A"
                        : e?.gstType === "IGST"
                        ? "₹" + e?.totalIgst
                        : "₹" + e?.totalCgst * 2}
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.discountValue > 0
                        ? e?.discountType === "percentage"
                          ? `${e?.discountValue}%`
                          : `₹${e?.discountValue}`
                        : 0}
                    </TableCell>
                    <TableCell className="text-center">
                      ₹{e?.totalPrice}
                    </TableCell>
                    <TableCell className="text-center">₹{e?.amount}</TableCell>
                    <TableCell className="text-center">
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <Button variant="outline">{e?.dealStatus}</Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            className="py-2 font-medium cursor-pointer hover:bg-slate-200 text-green-600"
                            onClick={() => changeStatus(e?._id, "Success")}
                          >
                            Success
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="py-2 font-medium cursor-pointer hover:bg-slate-200 text-red-500"
                            onClick={() => changeStatus(e?._id, "Cancel")}
                          >
                            Cancel
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                    <TableCell className="text-center">
                      <DialogBox
                        title={<CiEdit size={"20px"} />}
                        contentTitle={"Add A Remark"}
                      >
                        <div className="flex items-center w-full justify-between">
                          <InputField
                            placeholder="Write a remark"
                            onChange={(e) => setRemarks(e?.target?.value)}
                            defaultValue={e?.remark}
                          />
                          <Button onClick={() => addRemarks(e?._id)}>
                            Save
                          </Button>
                        </div>
                      </DialogBox>
                    </TableCell>
                    <TableCell className="text-center">
                      <Button variant="ghost">
                        <Link
                          to={`${SERVER_URL}/api/v1/invoice/generate/quotation/${e?._id}`}
                          target="_blank"
                        >
                          <IoMdCloudDownload
                            size={"28px"}
                            className="text-blue-500"
                          />
                        </Link>
                      </Button>
                    </TableCell>
                    <TableCell className="text-center">
                      <div
                        className="text-center w-full flex flex-col justify-center items-center cursor-pointer text-red-600"
                        onClick={() => deleteRecord(e?._id)}
                      >
                        <MdDelete size={"30px"} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default QuotationReport;
