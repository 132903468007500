import AlertBox from "@/components/AlertBox";
import DialogBox from "@/components/DialogBox";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { DialogClose } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoMdCloudDownload } from "react-icons/io";
import Loading from "@/components/Loading";
import { CiEdit } from "react-icons/ci";
import { TfiReload } from "react-icons/tfi";
import { MdDelete } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { useSelector } from "react-redux";

function InvoiceReport() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [recordData, setRecordData] = useState([]);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [tds, setTds] = useState(0);
  const [paymentRecived, setPaymentRecived] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [totalTds, setTotalTds] = useState(0);
  const [totalBillAmount, setTotalBillAmount] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalGst, setTotalGst] = useState(0);
  const [totalDue, setTotalDue] = useState(0);
  const [totalRecived, setTotalRecived] = useState(0);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const fileref = useRef(null);
  const [file, setFile] = useState(undefined);
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);

  if (!isAuthenticated) {
    return navigate("/");
  }

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/get/invoice/report?search=${search}&startDate=${startDate}&endDate=${endDate}`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data);
      setRecordData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchTotalValues() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/get/invoice/totals`,
        method: "get",
        withCredentials: true,
      });
      const values = res.data.data?.invoice[0];
      console.log(values);
      setTotalRevenue(values?.totalAmount);
      setTotalGst(
        Number(values?.totalIgst) + Number(values?.totalCgst) * 2 || 0
      );
      setTotalInvoice(values?.totalDocuments);
      setTotalBillAmount(values?.totalPrice);
      setTotalTds(values?.totalTDS);
      setTotalDue(values?.totalDue);
      setTotalRecived(values?.totalRecived);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteRecord(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setReload((prev) => !prev);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload, startDate, endDate]);

  useEffect(() => {
    fetchTotalValues();
  }, []);

  async function addTds(id, amount) {
    let finalAmount = Math.ceil(Number(amount - amount * (tds / 100)));
    let tdsAmount = Math.ceil(Number(amount * (tds / 100)));

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/add`,
        method: "post",
        data: {
          tdsPercentage: tds,
          tdsAmount: tdsAmount,
          finalBillAmount: finalAmount,
          id: id,
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("TDS deducted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("TDS deduction failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addPayment(id, totalPrice, prevRecived, prevDue) {
    if (paymentRecived > totalPrice || prevDue < paymentRecived) {
      setOpen(true);
      setSuccess(false);
      setMessage("Payment should be less than total bill and due payment");
      return null;
    }

    let duePayment =
      totalPrice - (Number(prevRecived) + Number(paymentRecived));
    let totalRecived = Math.ceil(Number(prevRecived) + Number(paymentRecived));

    let paymentStatus = false;

    if (duePayment === 0) {
      paymentStatus = true;
    }

    console.log(duePayment);
    console.log(paymentStatus);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/add`,
        method: "post",
        data: {
          paymentDue: duePayment,
          paymentRecived: totalRecived,
          id: id,
          paymentDetails: { isPaid: paymentStatus },
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addRemarks(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/add`,
        method: "post",
        data: {
          remark: remarks,
          id: id,
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addFile(id) {
    if (file === undefined) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select a file");
      return null;
    }

    const formData = new FormData();

    formData.append("challanImg", file);
    formData.append("id", id);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/add`,
        method: "post",
        data: formData,
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/formData",
        },
      });
      setReload((prev) => !prev);
      setFile(undefined);
      setOpen(true);
      setSuccess(true);
      setMessage("Challan added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Challan add failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function generateReport() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/generate/invoice/report`,
        method: "post",
        data: {
          startDate,
          endDate,
          data: recordData,
          topic: search,
        },
        withCredentials: true,
      });
      console.log(res.data.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Redirecting in some time...");
      window.open(res.data?.data?.link, "_blank");
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setMessage("Report generate failed");
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  //console.log(recordData);

  return (
    <div className="w-full flex flex-col justify-center items-center py-5 px-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <h1 className="text-[40px] font-semibold">Invoice Report</h1>
      <div className="w-full mt-5">
        <div className="w-full flex justify-between items-center gap-3">
          <div className="flex gap-2 items-center">
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {startDate?.toString()?.split(" ")?.slice(1, 4)?.join(" ") ||
                    "Start Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={startDate}
                  onSelect={setStartDate}
                />
              </PopoverContent>
            </Popover>
            <p>To</p>
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {endDate?.toString()?.split(" ")?.slice(1, 4)?.join(" ") ||
                    "End Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={endDate}
                  onSelect={setEndDate}
                />
              </PopoverContent>
            </Popover>
            <Button
              onClick={() => {
                setStartDate(undefined);
                setEndDate(undefined);
              }}
            >
              Clear
            </Button>
          </div>
          <div className="flex items-center gap-2">
            <section className="flex gap-2">
              <InputField
                placeholder="Search"
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <Button onClick={() => setReload((prev) => !prev)}>Search</Button>
            </section>
          </div>
        </div>
        <div className="flex justify-end items-center w-full mt-5 gap-3">
          <NavLink
            to={"/invoice/generate"}
            className="font-semibold text-blue-500 hover:underline"
          >
            New Invoice
          </NavLink>
          <Button
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => {
              setReload((prev) => !prev);
              setSearch("");
            }}
          >
            <TfiReload size={"20px"} /> Refresh
          </Button>
          <Button className="flex items-center gap-2" onClick={generateReport}>
            <IoMdCloudDownload size={"20px"} /> Download
          </Button>
        </div>
        <div className="w-full grid grid-cols-5 gap-3 mt-5">
          <section className="border max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Invoices
            </p>
            <p className="text-[30px] font-semibold">₹ {totalInvoice}</p>
          </section>
          <section className="border max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Bill Amount
            </p>
            <p className="text-[30px] font-semibold">₹ {totalBillAmount}</p>
          </section>
          <section className="border max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">Total GST</p>
            <p className="text-[30px] font-semibold">₹ {totalGst}</p>
          </section>
          <section className="border max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">Total TDS</p>
            <p className="text-[30px] font-semibold">₹ {totalTds}</p>
          </section>
          <section className="border max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Company Revenue
            </p>
            <p className="text-[30px] font-semibold">₹ {totalRevenue}</p>
          </section>
          <section className="border max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Due Amount
            </p>
            <p className="text-[30px] font-semibold">₹ {totalDue}</p>
          </section>
          <section className="border max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Recived Amount
            </p>
            <p className="text-[30px] font-semibold">₹ {totalRecived}</p>
          </section>
        </div>
        <div className="border mt-5 py-2 rounded-[8px]">
          <Table>
            <TableHeader>
              <TableRow className="text-[13px]">
                <TableHead className="text-center">Date</TableHead>
                <TableHead className="text-center">Client Name</TableHead>
                <TableHead className="text-center">Invoice Number</TableHead>
                <TableHead className="text-center">GST Type</TableHead>
                <TableHead className="text-center">GST Amount</TableHead>
                <TableHead className="text-center">Discount</TableHead>
                <TableHead className="text-center">TDS Deduction</TableHead>
                <TableHead className="text-center">Challan Deposit</TableHead>
                <TableHead className="text-center">
                  Total Bill(include GST)
                </TableHead>
                <TableHead className="text-center">
                  Total Amount(exclude GST)
                </TableHead>
                <TableHead className="text-center">Payment Recived</TableHead>
                <TableHead className="text-center">Payment Due</TableHead>
                <TableHead className="text-center">Payment Complete</TableHead>
                <TableHead className="text-center">Remarks</TableHead>
                <TableHead className="text-center">Download Invoice</TableHead>
                <TableHead className="text-center">Delete Invoice</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {recordData &&
                recordData?.map((e, i) => (
                  <TableRow
                    className={`text-[13px] ${
                      e?.paymentDetails?.isPaid === true
                        ? "bg-green-50"
                        : "bg-red-50"
                    }`}
                  >
                    <TableCell className="font-medium text-center">
                      {
                        new Date(e?.dateStamp)
                          ?.toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                          })
                          ?.toString()
                          ?.split(",")[0]
                      }
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.client?.name}
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.invoiceNumber}
                    </TableCell>
                    <TableCell className="text-center">{e?.gstType}</TableCell>
                    <TableCell className="text-center">
                      ₹{e?.gstType === "IGST" ? e?.totalIgst : e?.totalCgst * 2}
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.discountValue > 0
                        ? e?.discountType === "percentage"
                          ? `${e?.discountValue}%`
                          : `₹${e?.discountValue}`
                        : 0}
                    </TableCell>
                    <TableCell className="text-center">
                      <DialogBox
                        title={
                          <p className="flex items-center gap-1 font-medium">
                            {e?.tdsPercentage === undefined
                              ? 0
                              : e?.tdsPercentage}
                            %{" "}
                            <span>
                              <CiEdit size={"18px"} />
                            </span>
                          </p>
                        }
                        contentTitle={"Add TDS(%)"}
                      >
                        <div className="w-full flex justify-between items-center">
                          <InputField
                            placeholder="E.g, 2%"
                            onChange={(e) => setTds(e?.target?.value)}
                          />
                          <Button onClick={() => addTds(e?._id, e?.amount)}>
                            Save
                          </Button>
                        </div>
                      </DialogBox>
                    </TableCell>
                    <TableCell className="text-center">
                      <DialogBox
                        title={`${
                          e?.challanDeposit?.length > 0
                            ? "Challan Submitted"
                            : "Submit Challan"
                        }`}
                      >
                        <div className="w-full grid grid-cols-3 gap-5">
                          {e?.challanDeposit?.length > 0 ? (
                            <Link
                              to={`${SERVER_URL}/api/v1/file/image/${e?.challanDeposit}`}
                              target="_blank"
                            >
                              <div className="w-[10rem] h-[10rem]">
                                <img
                                  src={`${SERVER_URL}/api/v1/file/image/${e?.challanDeposit}`}
                                  className="w-full h-full object-cover"
                                />
                              </div>
                            </Link>
                          ) : (
                            <p>No documents available</p> // Optional: Handle case where there are no documents
                          )}
                        </div>
                        <div
                          onClick={() => fileref.current.click()}
                          className="w-full h-[5rem] bg-blue-50/60 rounded-3xl flex justify-center items-center flex-col gap-1 border-2 border-dotted border-gray-300 cursor-pointer"
                        >
                          <input
                            type="file"
                            className="hidden"
                            ref={fileref}
                            onChange={(e) => setFile(e?.target?.files[0])}
                            multiple
                          />
                          <FiPlus size={"20px"} />
                          <p className="text-[15px]">Upload Challan</p>
                        </div>
                        <Button onClick={() => addFile(e?._id)}>Add</Button>
                      </DialogBox>
                    </TableCell>
                    <TableCell className="text-center">
                      ₹{e?.totalPrice}
                    </TableCell>
                    <TableCell className="text-center">
                      ₹{e?.finalBillAmount}
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.paymentDue > 0 ? (
                        <DialogBox
                          title={
                            <p className="flex items-center gap-1 font-medium">
                              ₹{e?.paymentRecived}
                              <span>
                                <CiEdit size={"18px"} />
                              </span>
                            </p>
                          }
                          contentTitle={"Payment Recived"}
                        >
                          <div className="w-full flex justify-between items-center">
                            <InputField
                              placeholder="E.g, 5000"
                              onChange={(e) =>
                                setPaymentRecived(e?.target?.value)
                              }
                            />
                            <Button
                              onClick={() =>
                                addPayment(
                                  e?._id,
                                  e?.totalPrice,
                                  e?.paymentRecived,
                                  e?.paymentDue
                                )
                              }
                            >
                              Save
                            </Button>
                          </div>
                        </DialogBox>
                      ) : (
                        `₹${e?.paymentRecived}`
                      )}
                    </TableCell>
                    <TableCell className="text-center">
                      ₹{e?.paymentDue}
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.paymentDetails?.isPaid === true ? "Yes" : "No"}
                    </TableCell>
                    <TableCell className="text-center">
                      <DialogBox
                        title={<CiEdit size={"20px"} />}
                        contentTitle={"Add A Remark"}
                      >
                        <div className="flex items-center w-full justify-between">
                          <InputField
                            placeholder="Write a remark"
                            onChange={(e) => setRemarks(e?.target?.value)}
                            defaultValue={e?.remark}
                          />
                          <Button onClick={() => addRemarks(e?._id)}>
                            Save
                          </Button>
                        </div>
                      </DialogBox>
                    </TableCell>
                    <TableCell className="text-center">
                      <Button variant="ghost">
                        <Link
                          to={`${SERVER_URL}/api/v1/invoice/generate/invoice/${e?._id}`}
                          target="_blank"
                        >
                          <IoMdCloudDownload
                            size={"28px"}
                            className="text-blue-500"
                          />
                        </Link>
                      </Button>
                    </TableCell>
                    <TableCell className="text-center">
                      <div
                        className="text-center w-full flex flex-col justify-center items-center cursor-pointer text-red-600"
                        onClick={() => deleteRecord(e?._id)}
                      >
                        <MdDelete size={"30px"} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default InvoiceReport;
