import React, { useEffect, useState } from "react";
import DialogBox from "./DialogBox";
import InputField from "./InputField";
import { Button } from "./ui/button";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import AlertBox from "./AlertBox";
import { FiFilePlus } from "react-icons/fi";
import { Label } from "./ui/label";
import { DialogClose } from "./ui/dialog";
import { useSelector } from "react-redux";

function AddTAndC({ setReload, invoiceId }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [term, setTerm] = useState("");
  const [errors, setErrors] = useState({
    term: false
  });
  const userData = useSelector(state => state.auth?.userData)

  async function addItem() {
    if (term?.length === 0) {
      setErrors((prev) => {
        return {
          ...prev,
          term: true,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          term: false,
        };
      });
    }

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/term/add`,
        method: "post",
        data: {
          id: userData?._id,
          term: term,
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Add failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <DialogBox
        title={
          <p className="flex items-center gap-[7px] font-medium w-full justify-center">
            <FiFilePlus size={"18px"} />
            Add Terms & Conditions
          </p>
        }
        buttonClass={"text-center py-5 border-slate-300"}
        desc={"Add A New Item In The Invoice"}
        width="20px"
      >
        <div className="w-full flex justify-center items-center">
          <InputField
            label="Term"
            onChange={(e) => setTerm(e?.target?.value)}
            className={`${
              errors?.term === true ? "border-red-500 border" : ""
            }`}
          />
        </div>
        <DialogClose>
          <Button onClick={addItem} className="mt-5">
            Add
          </Button>
        </DialogClose>
      </DialogBox>
    </>
  );
}
export default AddTAndC;
