import React from "react";

function Loading({color}) {
  return (
    <div class="w-full flex space-x-2 justify-center items-center bg-transparent h-[100vh] dark:invert">
      <span class="sr-only">Loading...</span>
      <div class={`h-3 w-3 ${color} rounded-full animate-bounce [animation-delay:-0.3s]`}></div>
      <div class={`h-3 w-3 ${color} rounded-full animate-bounce [animation-delay:-0.15s]`}></div>
      <div class={`h-3 w-3 ${color} rounded-full animate-bounce`}></div>
    </div>
  );
}

export default Loading;