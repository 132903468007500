import AlertBox from "@/components/AlertBox";
import DialogBox from "@/components/DialogBox";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BiBookAdd } from "react-icons/bi";
import { FiFilter, FiPlus } from "react-icons/fi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoMdCloudDownload } from "react-icons/io";
import Loading from "@/components/Loading";
import { CiEdit } from "react-icons/ci";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SlCalender } from "react-icons/sl";
import ConfirmBox from "@/components/ConfirmBox";
import { TfiReload } from "react-icons/tfi";
import { useSelector } from "react-redux";

function Service() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [recordData, setRecordData] = useState([]);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [rate, setRate] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [name, setName] = useState("");
  const [selectedClient, setSelectedClient] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [typeOfService, setTypeOfService] = useState("");
  const [timeSpan, setTimeSpan] = useState(1);
  const [clientData, setClientData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownTitle, setDropdownTitle] = useState("Select A Client");
  const [endDate, setEndDate] = useState(undefined);
  const [startSearchDate, setStartSearchDate] = useState(undefined);
  const [enableAddService, setEnableAddService] = useState(false);
  const [enableEditService, setEnableEditService] = useState(false);
  const [editData, setEditData] = useState({});
  const navigate = useNavigate()
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated)

  if(!isAuthenticated) {
    return navigate("/")
  }

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/service/reports/due?search=${search}&startDate=${startSearchDate}&endDate=${endDate}`,
        method: "get",
        withCredentials: true,
      });
      let rawData = res.data.data;
      let finalData = [];
      rawData?.map((e, i) => {
        // Convert both dates to UTC timestamps in milliseconds
        const utcDate1 = Date.now(); // or date1.getTime() if it's already a Date object
        const utcDate2 = Date.parse(e?.dueDate); // or date2.getTime() if it's already a Date object

        // Calculate the difference in milliseconds
        const diffInMilliseconds = Math.abs(utcDate2 - utcDate1);

        // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
        const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

        const avgDaysInMonth = 30.44;
        const months = Math.floor(diffInDays / avgDaysInMonth);
        const days = Math.floor(diffInDays % avgDaysInMonth);

        finalData.push({
          ...e,
          remaining: `${months} months and ${days} days`,
        });
      });
      setRecordData(finalData);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload, startSearchDate, endDate]);

  //console.log(recordData);

  async function deleteRecord(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/service/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setReload((prev) => !prev);
    }
  }

  async function addRecord() {
    let data = {
      startDate: startDate,
      name: name,
      client: selectedClient,
      clientId: selectedClient?._id,
      typeOfService: typeOfService,
      remarks: remarks,
      timeSpan: timeSpan,
      amount: rate,
    };

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/service/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setEnableAddService(false);
      setOpen(true);
      setSuccess(true);
      setMessage("Record added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Record add failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function editRecord() {
    let data = {
      startDate: startDate,
      name: name,
      client: selectedClient,
      clientId: selectedClient?._id,
      typeOfService: typeOfService,
      remarks: remarks,
      timeSpan: timeSpan,
      amount: rate,
      id: editData?._id,
    };

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/service/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Record edited successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Record edit failed");
      console.log(error);
    } finally {
      setLoading(false);
      setEnableEditService(false);
    }
  }

  function selectAClient(data) {
    setSelectedClient(data);
    setDropdownTitle(data?.name);
    setDropdownOpen(false);
  }

  useEffect(() => {
    if (editData?.client !== undefined) {
      selectAClient(editData?.client);
      setStartDate(editData?.startDate);
    } else {
      setStartDate(new Date());
      setDropdownTitle("Select A Client");
    }
  }, [editData, enableAddService]);

  async function fetchClientData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/client/get`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data);
      setClientData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchClientData();
  }, []);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [toBePaidId, setToBePaidId] = useState("");

  function handleConfirm(id) {
    setConfirmDialogOpen(true);
    setToBePaidId(id);
    console.log(id);
  }

  console.log(toBePaidId);

  async function markAsPaid() {
    console.log(toBePaidId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/service/mark/paid/${toBePaidId}`,
        method: "put",
        withCredentials: true,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setConfirm(false);
      setLoading(false);
      setReload((prev) => !prev);
    }
  }

  console.log(toBePaidId);

  useEffect(() => {
    if (confirm === true) {
      markAsPaid();
    }
  }, [confirm]);

  async function generateReport() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/service/generate/service/report`,
        method: "post",
        data: {
          startDate: startSearchDate,
          endDate: endDate,
          data: recordData,
          topic: search,
        },
        withCredentials: true,
      });
      console.log(res.data.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Redirecting in some time...");
      window.open(res.data?.data?.link, "_blank");
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setMessage("Report generate failed");
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  if (enableAddService) {
    return (
      <div className="py-5 w-full flex flex-col justify-center items-center">
        <h1 className="text-[40px] font-semibold text-center">Add A Service</h1>
        <div className="w-[70vw] xl:w-[50vw]">
          <div className="w-full grid grid-cols-2 gap-3 mt-[5rem]">
            <InputField
              label="Service Name"
              onChange={(e) => setName(e?.target?.value)}
            />
            <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
              <div className="grid gap-2.5 max-w-[24rem]">
                <Label>Select A Client</Label>
                <DropdownMenuTrigger
                  onClick={() => setDropdownOpen((prev) => !prev)}
                  className="border w-full bg-white flex justify-between items-center min-h-9 px-3 text-left"
                >
                  {dropdownTitle} <IoIosArrowDown />
                </DropdownMenuTrigger>
              </div>
              <DropdownMenuContent>
                {clientData?.map((e, i) => (
                  <DropdownMenuItem
                    className="font-medium"
                    onClick={() => selectAClient(e)}
                    key={i}
                  >
                    {e?.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            <InputField
              label="Type Of Service"
              onChange={(e) => setTypeOfService(e?.target?.value)}
            />
            <InputField
              label="Rate"
              onChange={(e) => setRate(e?.target?.value)}
            />
            <Popover>
              <div className="grid gap-2.5 max-w-[24rem]">
                <Label>Start Date</Label>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="flex items-center gap-3 w-full border rounded-none"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {new Date(startDate)
                      .toLocaleDateString("en-US", {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                      .replace(/,/g, "")}{" "}
                    <SlCalender />
                  </Button>
                </PopoverTrigger>
              </div>
              <PopoverContent className="z-[]">
                <Calendar
                  mode="single"
                  selected={startDate}
                  onSelect={setStartDate}
                  // disabled={(date) => {
                  //   let today = new Date();
                  //   today.setHours(0, 0, 0, 0);
                  //   date.setHours(0, 0, 0, 0);
                  //   return date < today;
                  // }}
                />
              </PopoverContent>
            </Popover>
            <InputField
              label="Pay Every(in months)"
              placeholder="E.g, 1,2,3 etc."
              onChange={(e) => setTimeSpan(e?.target?.value)}
            />
          </div>
          <div className="w-full space-y-2">
            <Label>Remarks</Label>
            <textarea
              className="w-full focus:outline-none focus:border-slate-500 border p-3 rounded-[5px] h-[7rem]"
              onChange={(e) => setRemarks(e?.target?.value)}
            ></textarea>
          </div>
          <div className="w-full flex justify-end gap-3 items-center">
            <Button onClick={() => setEnableAddService(false)} className="mt-5">
              Back
            </Button>
            <Button onClick={addRecord} className="mt-5">
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }

  console.log(editData);

  if (enableEditService) {
    return (
      <div className="py-5 w-full flex flex-col justify-center items-center">
        <h1 className="text-[40px] font-semibold text-center">
          Edit A Service
        </h1>
        <div className="w-[70vw] xl:w-[50vw]">
          <div className="w-full grid grid-cols-2 gap-3 mt-[5rem]">
            <InputField
              label="Service Name"
              defaultValue={editData?.name}
              onChange={(e) => setName(e?.target?.value)}
            />
            <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
              <div className="grid gap-2.5 max-w-[24rem]">
                <Label>Select A Client</Label>
                <DropdownMenuTrigger
                  onClick={() => setDropdownOpen((prev) => !prev)}
                  className="border w-full bg-white flex justify-between items-center min-h-9 px-3 text-left"
                >
                  {dropdownTitle} <IoIosArrowDown />
                </DropdownMenuTrigger>
              </div>
              <DropdownMenuContent>
                {clientData?.map((e, i) => (
                  <DropdownMenuItem
                    className="font-medium"
                    onClick={() => selectAClient(e)}
                    key={i}
                  >
                    {e?.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            <InputField
              label="Type Of Service"
              defaultValue={editData?.typeOfService}
              onChange={(e) => setTypeOfService(e?.target?.value)}
            />
            <InputField
              label="Rate"
              defaultValue={editData?.amount}
              onChange={(e) => setRate(e?.target?.value)}
            />
            <Popover>
              <div className="grid gap-2.5 max-w-[24rem]">
                <Label>Start Date</Label>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="flex items-center gap-3 w-full border rounded-none"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {new Date(startDate)
                      .toLocaleDateString("en-US", {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                      .replace(/,/g, "")}{" "}
                    <SlCalender />
                  </Button>
                </PopoverTrigger>
              </div>
              <PopoverContent className="z-[]">
                <Calendar
                  mode="single"
                  selected={startDate}
                  onSelect={setStartDate}
                  // disabled={(date) => {
                  //   let today = new Date();
                  //   today.setHours(0, 0, 0, 0);
                  //   date.setHours(0, 0, 0, 0);
                  //   return date < today;
                  // }}
                />
              </PopoverContent>
            </Popover>
            <InputField
              label="Pay Every(in months)"
              defaultValue={editData?.timeSpan}
              placeholder="E.g, 1,2,3 etc."
              onChange={(e) => setTimeSpan(e?.target?.value)}
            />
          </div>
          <div className="w-full space-y-2">
            <Label>Remarks</Label>
            <textarea
              className="w-full focus:outline-none focus:border-slate-500 border p-3 rounded-[5px] h-[7rem]"
              onChange={(e) => setRemarks(e?.target?.value)}
            ></textarea>
          </div>
          <div className="w-full flex justify-end gap-3 items-center">
            <Button
              onClick={() => setEnableEditService(false)}
              className="mt-5"
            >
              Back
            </Button>
            <Button onClick={editRecord} className="mt-5">
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }

  //console.log(recordData);

  return (
    <div className="w-full flex flex-col justify-center items-center py-5 px-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <ConfirmBox
        setAlertDialouge={setConfirmDialogOpen}
        setConfirm={setConfirm}
        title={"Payment Done?"}
        open={confirmDialogOpen}
      />
      <h1 className="text-[40px] font-semibold">Due Subscription Reminder</h1>
      <div className="w-full mt-5">
        <div className="w-full flex justify-between items-center gap-3">
          <div className="flex gap-2 items-center">
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {startSearchDate
                    ?.toString()
                    ?.split(" ")
                    ?.slice(1, 4)
                    ?.join(" ") || "Start Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={startSearchDate}
                  onSelect={setStartSearchDate}
                />
              </PopoverContent>
            </Popover>
            <p>To</p>
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {endDate?.toString()?.split(" ")?.slice(1, 4)?.join(" ") ||
                    "End Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={endDate}
                  onSelect={setEndDate}
                />
              </PopoverContent>
            </Popover>
            <Button
              onClick={() => {
                setStartDate(undefined);
                setEndDate(undefined);
              }}
            >
              Clear
            </Button>
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              onClick={() => {
                setEnableAddService(true);
                setEditData({});
              }}
            >
              <p className="flex items-center gap-1 font-medium max-w-full">
                <BiBookAdd size={"18px"} /> Add New Service
              </p>
            </Button>
            <section className="flex gap-2">
              <InputField
                placeholder="Search"
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <Button onClick={() => setReload((prev) => !prev)}>Search</Button>
            </section>
          </div>
        </div>
        <div className="flex justify-end w-full mt-5 gap-3 items-center">
          <NavLink to={"/report/service"} className="font-semibold text-blue-500 hover:underline">View Service Reports</NavLink>
          <Button
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => {
              setReload((prev) => !prev);
              setSearch("");
            }}
          >
            <TfiReload size={"20px"} /> Refresh
          </Button>
          <Button className="flex items-center gap-2" onClick={generateReport}>
            <IoMdCloudDownload size={"20px"} /> Download
          </Button>
        </div>
        <div className="border mt-5 px-5 rounded-[8px]">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-center">Service Name</TableHead>
                <TableHead className="text-center">Client</TableHead>
                <TableHead className="text-center">Amount</TableHead>
                <TableHead className="text-center">Type Of Service</TableHead>
                <TableHead className="text-center">Start Date</TableHead>
                <TableHead className="text-center">
                  Pay Every(in months)
                </TableHead>
                <TableHead className="text-center">Due Date</TableHead>
                <TableHead className="text-center">Payment In</TableHead>
                <TableHead className="text-center">Remarks</TableHead>
                <TableHead className="text-center">Status</TableHead>
                <TableHead className="text-center">Edit</TableHead>
                <TableHead className="text-center">Delete</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {recordData &&
                recordData?.map((e, i) => (
                  <TableRow key={i} className="bg-red-50">
                    <TableCell className="text-center">{e?.name}</TableCell>
                    <TableCell className="text-center">
                      {e?.client?.name}
                    </TableCell>
                    <TableCell className="text-center">{e?.amount}</TableCell>
                    <TableCell className="text-center">
                      {e?.typeOfService}
                    </TableCell>
                    <TableCell className="text-center">
                      {
                        new Date(e?.startDate)
                          ?.toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                          })
                          ?.toString()
                          ?.split(",")[0]
                      }
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.timeSpan} months
                    </TableCell>
                    <TableCell className="text-center">
                      {
                        new Date(e?.dueDate)
                          ?.toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                          })
                          ?.toString()
                          ?.split(",")[0]
                      }
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.remaining}
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.remarks?.length > 0 ? e?.remarks : "None"}
                    </TableCell>
                    <TableCell className="text-center">
                      <Button onClick={() => handleConfirm(e?._id)}>
                        Mark As Paid
                      </Button>
                    </TableCell>
                    <TableCell className="text-center">
                      <Button
                        variant="outline"
                        onClick={() => {
                          setEnableEditService(true);
                          setEditData(e);
                        }}
                      >
                        <CiEdit size={"20px"} />
                      </Button>
                    </TableCell>
                    <TableCell className="text-center">
                      <Button
                        variant="destructive"
                        onClick={() => deleteRecord(e?._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default Service;
