import AlertBox from "@/components/AlertBox";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { MdEditSquare } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Label } from "@/components/ui/label";
import { LuPencilLine } from "react-icons/lu";
import AddTAndC from "@/components/AddTAndC";
import { RxCross1 } from "react-icons/rx";

function Profile() {
  const [userData, setUserData] = useState({});
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const logoInputRef = useRef(null);
  const qrInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [reload, setReload] = useState(false);
  const [state, setState] = useState("");
  const navigate = useNavigate();
  const signref = useRef(null);
  const [selectedSign, setSelectedSign] = useState("");
  const [selectedterms, setSelectedTerms] = useState([]);

  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);

  if (!isAuthenticated) {
    return navigate("/");
  }

  async function fetchUserData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/get-user`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setUserData(res.data.data);
      setSelectedSign(res.data.data?.signature);
      setSelectedTerms(res.data.data?.termsAndCond);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchUserData();
  }, [reload]);

  async function saveInfo(data) {
    data = { ...data, id: userData?._id, state: state };
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/update`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleLogoChange = async (event) => {
    const file = event.target.files[0];

    const formData = new FormData();

    formData.append("logo", file);
    formData.append("id", userData?._id);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/update`,
        method: "post",
        data: formData,
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Logo updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Logo update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleQrChange = async (event) => {
    const file = event.target.files[0];

    const formData = new FormData();

    formData.append("qrImg", file);
    formData.append("id", userData?._id);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/update`,
        method: "post",
        data: formData,
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Logo updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Logo update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignChange = async (event) => {
    const file = event.target.files[0];

    const formData = new FormData();

    formData.append("signature", file);
    formData.append("id", userData?._id);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/update`,
        method: "post",
        data: formData,
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Logo updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Logo update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  async function deleteTerm(item) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/term/delete`,
        method: "delete",
        data: {
          id: userData?._id,
          term: item,
        },
        withCredentials: true,
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setReload((prev) => !prev);
    }
  }

  useEffect(() => {
    setState(userData?.state);
  }, [userData]);

  return (
    <div className="w-full flex flex-col justify-center items-center py-[2rem]">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <h1 className="text-[40px] font-semibold">My Profile</h1>
      <div className="w-[80vw] border shadow-lg px-10 py-5 mt-10">
        <form
          className="w-full mt-[1rem] space-y-10"
          onSubmit={handleSubmit(saveInfo)}
        >
          <div className="flex justify-center items-center gap-5">
            <div className="flex flex-col justify-center items-center w-[10rem]">
              <input
                type="file"
                ref={logoInputRef}
                style={{ display: "none" }}
                onChange={handleLogoChange}
              />
              <img
                src={`${SERVER_URL}/api/v1/file/image/${userData?.logo}`}
                className="w-[10rem]"
                alt=""
              />
              <div className="w-full flex justify-end items-end mt-[-1.5rem] ml-[4rem]">
                <button
                  type="button"
                  onClick={() => logoInputRef.current.click()}
                  className=""
                >
                  <MdEditSquare className="text-[23px] text-slate-700 hover:bg-slate-100" />
                </button>
              </div>
            </div>
          </div>
          <div>
            <p className="text-[20px] mb-[2rem] border-l-2 pl-3 text-slate-800 font-semibold">
              Basic Information
            </p>
            <section className="grid grid-cols-1 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 justify-between items-center gap-5 place-items-center">
              <InputField
                label={
                  <p className="font-medium">
                    Full Name <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Full Name"
                {...register("name")}
                defaultValue={userData?.name}
              />
              <InputField
                label={
                  <p className="font-medium">
                    Email <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Email"
                type="email"
                {...register("email")}
                defaultValue={userData?.email}
              />
              <InputField
                label={
                  <p className="font-medium">
                    Phone Number <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Phone Number"
                {...register("phoneNumber")}
                defaultValue={userData?.phoneNumber}
              />
              <InputField
                label={
                  <p className="font-medium">
                    Business Website <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Business Website"
                {...register("website")}
                defaultValue={userData?.website}
              />
              <InputField
                label={
                  <p className="font-medium">
                    GST No <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="GST No"
                {...register("gstNo")}
                defaultValue={userData?.gstNo}
              />
              <InputField
                label={
                  <p className="font-medium">
                    PAN No <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="PAN No"
                {...register("panNo")}
                defaultValue={userData?.panNo}
              />
              <InputField
                label={
                  <p className="font-medium">
                    CIN No <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="CIN No"
                {...register("cinNo")}
                defaultValue={userData?.cinNo}
              />
              <InputField
                label={
                  <p className="font-medium">
                    Certificate No <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Certificate No"
                {...register("certificateNo")}
                defaultValue={userData?.certificateNo}
              />
            </section>
          </div>
          <div>
            <p className="text-[20px] border-l-2 pl-3 text-slate-800 font-semibold">
              Bank Details
            </p>
            <div className="w-[8rem] h-[8rem]">
              <input
                type="file"
                ref={qrInputRef}
                style={{ display: "none" }}
                onChange={handleQrChange}
              />
              <img
                src={`${SERVER_URL}/api/v1/file/image/${userData?.qrUrl}`}
                className="wfull h-full object-cover"
                alt=""
              />
              <div className="w-full flex justify-end items-end mt-[-2.5rem]">
                <button
                  type="button"
                  onClick={() => qrInputRef.current.click()}
                  className=""
                >
                  <MdEditSquare className="text-[23px] text-slate-700 hover:bg-slate-100" />
                </button>
              </div>
            </div>
            <section className="grid grid-cols-1 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 justify-between items-center gap-5 place-items-center">
              <InputField
                label={
                  <p className="font-medium">
                    Bank Name <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Bank Name"
                {...register("bankName")}
                defaultValue={userData?.bankName}
              />
              <InputField
                label={
                  <p className="font-medium">
                    Branch <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Branch"
                {...register("branch")}
                defaultValue={userData?.branch}
              />
              <InputField
                label={
                  <p className="font-medium">
                    IFS Code <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="IFS Code"
                {...register("ifsCode")}
                defaultValue={userData?.ifsCode}
              />
              <InputField
                label={
                  <p className="font-medium">
                    Account No <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Account No"
                {...register("acNo")}
                defaultValue={userData?.acNo}
              />
              <InputField
                label={
                  <p className="font-medium">
                    Account Holder <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Account Holder"
                {...register("acHolder")}
                defaultValue={userData?.acHolder}
              />
              <InputField
                label={
                  <p className="font-medium">
                    UPI ID <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="UPI ID"
                {...register("upiId")}
                defaultValue={userData?.upiId}
              />
            </section>
          </div>
          <div>
            <p className="text-[20px] mb-[2rem] border-l-2 pl-3 text-slate-800 font-semibold">
              Address
            </p>
            <section className="grid grid-cols-1 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 justify-between items-center gap-5 place-items-center">
              <InputField
                label={
                  <p className="font-medium">
                    Address <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Address"
                {...register("address")}
                defaultValue={userData?.address}
              />
              <InputField
                label={
                  <p className="font-medium">
                    Pincode <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="Pincode"
                {...register("pincode")}
                defaultValue={userData?.pincode}
              />
              <InputField
                label={
                  <p className="font-medium">
                    City <span className="text-red-500">*</span>
                  </p>
                }
                placeholder="City"
                {...register("city")}
                defaultValue={userData?.city}
              />
              <section className="flex flex-col gap-[0.725rem] max-w-[27rem]">
                <Label>Select State</Label>
                <select
                  className="max-w-[27rem] h-[2.3rem] p-[0.37rem] rounded-md bg-transparent border"
                  value={state}
                  onChange={(e) => setState(e?.target?.value)}
                >
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="West Bengal">West Bengal</option>
                  <option value="Andaman and Nicobar Islands">
                    Andaman and Nicobar Islands
                  </option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Dadra and Nagar Haveli">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Daman and Diu">Daman and Diu</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Lakshadweep">Lakshadweep</option>
                  <option value="Puducherry">Puducherry</option>
                </select>
              </section>
              <InputField
                label={
                  <p className="font-medium">
                    Country <span className="text-red-500">*</span>
                  </p>
                }
                readOnly
                placeholder="Country"
                {...register("country")}
                defaultValue={userData?.country}
              />
            </section>
          </div>
          <div>
            <p className="text-[20px] mb-[2rem] border-l-2 pl-3 text-slate-800 font-semibold">
              Signature
            </p>
            <section className="grid grid-cols-1 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 justify-between items-center gap-5 place-items-center">
              <div className="w-full">
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => handleSignChange(e)}
                  ref={signref}
                />
                {selectedSign?.length > 0 ? (
                  <div className="w-[13rem]">
                    <input
                      type="file"
                      ref={signref}
                      style={{ display: "none" }}
                      onChange={handleSignChange}
                    />
                    <img
                      src={`${SERVER_URL}/api/v1/file/image/${userData?.signature}`}
                      className="w-[13rem]"
                      alt=""
                    />
                    <div className="w-full flex justify-end items-end mt-[-1.5rem] ml-[2rem]">
                      <button
                        type="button"
                        onClick={() => signref.current.click()}
                        className=""
                      >
                        <MdEditSquare className="text-[23px] text-slate-700 hover:bg-slate-100" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => signref.current.click()}
                    className="w-full h-[4rem] bg-sky-200/30 rounded-[20px] border-dashed border-slate-400 border flex justify-center items-center gap-1 text-slate-700 cursor-pointer"
                  >
                    <LuPencilLine />
                    <p>
                      Add Signature <span className="text-red-500">*</span>
                    </p>
                  </div>
                )}
              </div>
            </section>
          </div>
          <div>
            <div className="w-full bg-white rounded-[10px]">
              <div className="flex justify-between items-center w-full">
                <p className="text-[20px] border-l-2 pl-3 text-slate-800 font-semibold">
                  Terms & Conditions
                </p>
                {selectedterms?.length >= 5 ? (
                  ""
                ) : (
                  <AddTAndC invoiceId={userData?._id} setReload={setReload} />
                )}
              </div>
              <div className="w-full flex flex-col gap-2 justify-center items-center mt-3">
                {selectedterms?.length > 0
                  ? selectedterms?.map((e, i) => (
                      <div className="w-full flex items-center justify-between">
                        <section className="flex gap-5 pb-2 items-end justify-start border-b-[1px] w-full border-slate-400">
                          <p>{i + 1}</p>
                          <p className="text-slate-800 font-medium" key={i}>
                            {e}
                          </p>
                        </section>
                        <Button variant="ghost" onClick={() => deleteTerm(e)}>
                          <RxCross1 />
                        </Button>
                      </div>
                    ))
                  : <p className="text-slate-500">No terms and condition is added yet</p>}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <Button className="rounded-md">Update</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profile;
