import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

export default function DialogBox({
  title,
  contentTitle,
  buttonClass,
  icon,
  desc,
  footer,
  children,
  width="370px",
  tAndCs = false,
  onOpenChange,
  dialogOpen,
}) {
  //console.log(dialogOpen);
  if (tAndCs) {
    return (
      <Dialog>
        <DialogTrigger asChild>
          <Button variant="ghost" className={buttonClass}>
            {title} {icon && icon}
          </Button>
        </DialogTrigger>
        <DialogContent className={`w-[100vw] sm:[90vw] h-[80vh] overflow-auto`}>
          <DialogHeader>
            <DialogTitle>{contentTitle || title}</DialogTitle>
            {desc && <DialogDescription>{desc}</DialogDescription>}
          </DialogHeader>
          {children}
          {footer && <DialogFooter>{footer}</DialogFooter>}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button variant="outline" className={buttonClass}>
          {title} {icon && icon}
        </Button>
      </DialogTrigger>
      <DialogContent
        className={`sm:max-w-[${width}] h-[auto] max-h-[90vh] overflow-auto`}
      >
        <DialogHeader>
          <DialogTitle>{contentTitle || title}</DialogTitle>
          {desc && <DialogDescription className="text-center">{desc}</DialogDescription>}
        </DialogHeader>
        {children}
        {footer && <DialogFooter>{footer}</DialogFooter>}
      </DialogContent>
    </Dialog>
  );
}
