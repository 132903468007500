import React, { useState } from "react";
import logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";
import { IoHomeOutline } from "react-icons/io5";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { Button } from "./ui/button";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import { logout } from "@/store/auth.slice";
import { SiWhatsapp } from "react-icons/si";

function Header() {
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const [open, setOpen] = useState(null);
  const [navOpen, setNavOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const items = [
    {
      heading: "Company Profile",
      link: "/my-profile",
    },
    {
      heading: "Generate Bill",
      subNavArray: [
        {
          headings: "Invoice",
          link: "/invoice/generate",
        },
        {
          headings: "Quotetion",
          link: "/quotation/generate",
        },
      ],
    },
    {
      heading: "Account Book",
      link: "/account-book",
    },
    {
      heading: "Inventory",
      link: "/inventory",
    },
    {
      heading: "CRM",
      subNavArray: [
        {
          headings: "All Clients",
          link: "/crm",
        },
        {
          headings: "Add Client",
          link: "/add-client",
        },
      ],
    },
    {
      heading: "Subscriptions Alert",
      link: "/services",
    },
    {
      heading: "Report",
      subNavArray: [
        {
          headings: "Invoice",
          link: "/report/invoice",
        },
        {
          headings: "Quotation",
          link: "/report/quotation",
        },
        {
          headings: "Subscriptions",
          link: "/report/service",
        },
      ],
    },
  ];

  async function logoutUser() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/logout`,
        method: "get",
        withCredentials: true,
      });
      if (res.status >= 200 || res.status < 400) {
        dispatch(logout());
        navigate("/");
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  if (!isAuthenticated) {
    return (
      <header className="px-5 py-5 bg-blue-50">
        <Link to={"/"}>
          <img src={logo} className="w-[10rem]" alt="" />
        </Link>
      </header>
    );
  }

  return (
    <div className="w-[100%] border-slate-200 border h-[4rem] bg-white top-0 left-0 fixed 2xl:w-[15rem] z-[1] 2xl:h-[100vh]">
      <div className="w-full flex justify-between items-center py-2 px-2 2xl:hidden ">
        <Button variant="ghost" onClick={() => setNavOpen(true)}>
          <RxHamburgerMenu className="text-[30px]" />
        </Button>
        <Link to={"/"}>
          <img src={logo} className="w-[8rem]" alt="" />
        </Link>
      </div>
      <div className="w-full hidden justify-center items-center py-5 2xl:flex flex-col">
        <Link to={"/"}>
          <img src={logo} className="w-[10rem]" alt="" />
        </Link>
        <div className="w-full flex flex-col mt-10 gap-5">
          <span className="flex items-center gap-3 border-l-[5px] border-sky-600 px-5 h-[3rem] bg-sky-100">
            <IoHomeOutline size={"15px"} />
            <Link to={"/"} className="hover:text-sky-500">
              Dashboard
            </Link>
          </span>
          <div className="px-3 w-full flex flex-col gap-5">
            {items.map((e, index) =>
              dropdown(
                e.heading,
                index,
                e.subNavArray,
                e.link,
                e.icon,
                open,
                setOpen,
                index
              )
            )}
            <Link
              to={
                "https://wa.me/9148054805?text=Hello%21%20I%20have%20a%20query%3F"
              }
              target="_blank"
              className="text-blue-500 w-full pl-4 font-bold flex items-center gap-2"
            >
              Help
              <SiWhatsapp size={"17px"} />
            </Link>
            <Button
              className="text-[16px] bg-red-500 hover:bg-red-700 font-semibold"
              onClick={logoutUser}
            >
              <IoIosLogOut className="text-white mr-3" size={"25px"} /> Logout
            </Button>
          </div>
        </div>
      </div>

      <div
        className={`w-full h-[100vh] bg-[#63636348] fixed top-0 left-0 transition-all duration-300 ease-in-out ${
          navOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={(e) => {
          setNavOpen(false);
        }}
      >
        <div
          className={`w-[15rem] bg-white h-full fixed left-0 top-0 py-5 flex flex-col items-center 
          transition-transform duration-300 ease-in-out transform ${
            navOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <Link to={"/"}>
            <img src={logo} className="w-[10rem]" alt="" />
          </Link>
          <div className="w-full flex flex-col mt-10 gap-5">
            <span className="flex items-center gap-3 border-l-[5px] border-sky-600 px-5 h-[3rem] bg-sky-100">
              <IoHomeOutline size={"15px"} />
              <Link to={"/"} className="hover:text-sky-500">
                Dashboard
              </Link>
            </span>
            <div className="px-3 w-full flex flex-col gap-5">
              {items.map((e, index) =>
                dropdown(
                  e.heading,
                  index,
                  e.subNavArray,
                  e.link,
                  e.icon,
                  open,
                  setOpen,
                  index,
                  setNavOpen
                )
              )}
              <Link
                to={
                  "https://wa.me/9148054805?text=Hello%21%20I%20have%20a%20query%3F"
                }
                target="_blank"
                className="text-blue-500 w-full pl-4 font-bold flex items-center gap-2"
              >
                Help
                <SiWhatsapp size={"17px"} />
              </Link>
              <Button
                className="text-[16px] bg-red-500 hover:bg-red-700 font-semibold"
                onClick={logoutUser}
              >
                <IoIosLogOut className="text-white mr-3" size={"25px"} /> Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function dropdown(
    heading,
    index,
    subNavArray,
    link,
    icon,
    open,
    setOpen,
    key,
    setNavOpen
  ) {
    const openDropdown = open === index;

    return (
      <section className="w-full" key={key} onClick={() => setNavOpen(false)}>
        <div
          className={`flex justify-between cursor-pointer items-center w-full px-4 rounded-2xl font-medium ${
            openDropdown === true ? "text-slate-900" : "bg-white text-slate-400"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(openDropdown ? null : index);
          }}
        >
          <span className="flex items-center gap-3">
            {subNavArray && (
              <h1 className="hover:text-sky-500 font-semibold">{heading}</h1>
            )}
            {!subNavArray && (
              <h1
                className="hover:text-sky-500 font-semibold"
                onClick={() => setNavOpen(false)}
              >
                <Link to={link} className="w-full font-semibold">
                  {heading}
                </Link>
              </h1>
            )}
          </span>
          {subNavArray && openDropdown === true ? (
            <FaMinus size={"12px"} className="text-slate-500 hidden lg:block" />
          ) : subNavArray?.length > 0 ? (
            <FaPlus size={"12px"} className="text-slate-500 hidden lg:block" />
          ) : null}
        </div>
        <ul className="w-full mt-2 border-l-[1px] pl-[1.5rem] space-y-3 border-sky-500">
          {openDropdown &&
            subNavArray &&
            subNavArray.map((e, index) => (
              <li key={index}>
                <Link
                  to={e.link}
                  className="text-slate-500 hover:text-slate-600 w-full"
                >
                  {e.headings}
                </Link>
              </li>
            ))}
        </ul>
      </section>
    );
  }
}

export default Header;
