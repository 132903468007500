import React, { useState } from "react";
import CardComponent from "@/components/CardComponent";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import AlertBox from "@/components/AlertBox";
import Loading from "@/components/Loading";
import { SERVER_URL } from "@/constant";
import { useDispatch } from "react-redux";
import { login } from "@/store/auth.slice";
import signinImg from "@/assets/signin.jpg";
import loginVid from "@/assets/login.mp4";

function Signin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  async function signup(data) {
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/login`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      if (res.status >= 200 || res.status < 400) {
        setOpen(true);
        setSuccess(true);
        //console.log(res.data.data);
        dispatch(login({ isAuthenticated: true }));
        navigate("/");
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full flex h-[92vh]">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Sign Up Successfull"}
          desc={"Please log in to your account."}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <div className="w-full lg:w-1/2 h-full flex items-center justify-center flex-col gap-10">
        <img src={logo} className="w-[10rem]" alt="" />
        <CardComponent
          width="w-[17rem] md:w-[23rem]"
          title={"Log In"}
          desc="Please log in to your account"
        >
          <form className="space-y-5 mt-[10px]" onSubmit={handleSubmit(signup)}>
            <InputField
              label={"Email"}
              placeholder={"E.g, abcd@example.com"}
              type={"email"}
              {...register("email", {
                required: true,
              })}
            />
            {errors?.email && (
              <p className="text-red-500 text-[14px]">*Email is required</p>
            )}
            <InputField
              label={"Password"}
              placeholder={"E.g, Abcd@345"}
              type={"password"}
              {...register("password", {
                pattern: {
                  value:
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                  message:
                    "Minimum an uppercase letter, a lowercase letter, a number and a special character is required",
                },
                min: {
                  value: 8,
                  message: "Password should contain minimum 8 characters",
                },
              })}
            />
            {errors?.password && (
              <p className="text-red-500 text-[14px]">
                {errors?.password?.message}
              </p>
            )}
            <Button className="w-full text-[16px]">
              {loading === true ? <Loading /> : "Sign In"}
            </Button>
          </form>
          {/* <Link
            className="w-full text-center mt-3 text-[14px] text-blue-600 font-medium block"
            to={"/forgot-password"}
          >
            Forgot Password?
          </Link> */}
        </CardComponent>
      </div>
      <div className={`w-1/2 h-[90vh] bg-center bg-cover hidden lg:flex`}>
        <video
         src={loginVid}
         autoPlay
         loop
         muted
         playsInline
         className="w-full"
        />
      </div>
    </div>
  );
}

export default Signin;
